<template>
    <div
        class="lookInfo flex-row-box flex-justify-center flex-cont-center"
        @click="closeBox"
    >
        <div class="lookInfoBox">
            <div class="flex-row-box headerList">
                <div class="exportTitle" />
                <div class="flex-1 text-center">查看课程</div>
                <div class="text-center exportTitle" @click="closeOver">
                    <i class="el-icon-close" />
                </div>
            </div>
            <div class="flex-row-box flex-justify-center lookInfoList">
                <div class="lookInfoL">
                    <!-- <div class="infoImg"><img :src="$fileUrl + headUrl"></div> -->
                    <div class="flex-row-box">
                        <div class="infoName">
                            <span style="color: #ff0000">*</span>课程名称:
                        </div>
                        <div class="infoText">
                            {{ courseName ? courseName : "" }}
                        </div>
                    </div>
                    <div class="flex-row-box pt-1">
                        <div class="infoName">
                            <span style="color: #ff0000">*</span>生效时间:
                        </div>
                        <div class="infoText">
                            {{ startTime ? startTime : "" }}
                        </div>
                    </div>
                    <div class="flex-row-box pt-1">
                        <div class="infoName">
                            <span style="color: #ff0000">*</span>分配企业:
                        </div>
                        <!-- <div class="infoText">{{courseName ? courseName : ''}}</div> -->
                        <el-select v-model="value">
                            <el-option
                                v-for="item in courseCompanyVos"
                                :key="item.id"
                                :label="item.companyName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
					<div class="flex-row-box pt-1">
					    <div class="infoName">
					        <span style="color: #ff0000">*</span>行业:
					    </div>
					    <div class="infoText">
					        {{ industryName ? industryName : "" }}
					    </div>
					</div>
                    <div class="flex-row-box pt-1">
                        <div class="infoName">
                            <span style="color: #ff0000">*</span>课程分类:
                        </div>
                        <div class="infoText">
                            {{ classifyIdStr ? classifyIdStr : "" }}
                        </div>
                    </div>
                    <div class="flex-row-box pt-1">
                        <div class="infoName">
                            <span style="color: #ff0000">*</span>培训类型:
                        </div>
                        <div class="infoText">
                            {{ trainTypeStr ? trainTypeStr : "" }}
                        </div>
                    </div>
                    <div class="flex-row-box pt-1">
                        <div class="infoName">
                            <span style="color: #ff0000">*</span>课程类型:
                        </div>
                        <div class="infoText">
                            {{ courseTypeStr ? courseTypeStr : "" }}
                        </div>
                    </div>
                    <div class="flex-row-box flex-cont-center pt-1">
                        <div class="infoName">
                            <span style="color: #ff0000">*</span>课程介绍:
                        </div>
                        <div class="infoText">
                            {{ courseIntroduce ? courseIntroduce : "" }}
                        </div>
                    </div>
                    <div class="flex-row-box pt-2">
                        <div class="infoName">
                            <span style="color: #ff0000">*</span>课程封面:
                        </div>
                        <div class="infoImg">
                            <img :src="$fileUrl + coursesCoverUrl" />
                        </div>
                    </div>
                </div>
                <div class="lookInfoR">
                    <div class="lookInfoTable">
                        <el-table
                            border
                            :data="tableData"
                            highlight-current-row
                            height="600"
                            style="width: 100%"
                        >
                            <el-table-column type="index" />
                            <el-table-column type="expand">
                                <template v-slot="{ row }">
                                    <el-table
                                        border
                                        :data="row.videoTopicDetailsVos"
                                        highlight-current-row
                                        height="300"
                                        style="width: 100%"
                                    >
                                        <el-table-column
                                            label="试题标题"
                                            prop="topicTitle"
                                        />
                                        <el-table-column
                                            label="试题分数"
                                            prop="fraction"
                                        />
                                        <el-table-column
                                            label="试题类型"
                                            prop="topicTypeStr"
                                        />
                                        <el-table-column
                                            label="启用状态"
                                            prop="statusStr"
                                        />
                                        <el-table-column
                                            label="试题解析"
                                            prop="topicParsing"
                                        />
                                    </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="视频名称"
                                prop="videoTitle"
                            />
                            <el-table-column
                                label="视频长度"
                                prop="videoLengthFormat"
                            />
                            <el-table-column
                                label="授课老师"
                                prop="instructor"
                            />
                            <el-table-column
                                label="考试次数"
                                prop="testCount"
                            />
                            <el-table-column
                                label="随机题目数量"
                                prop="randomTopic"
                            />
                            <el-table-column label="视频介绍" prop="remarks" />
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { courseDetails } from "@/api";
export default {
    name: "Info",
    props: {
        row: {
            type: Object, // 指定传入的类型
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: true,
            tableData: [],
            realName: "",
            phone: "",
            idCard: "",
            regionName: "",
            sexName: "",
            courseIntroduce: "",
            courseName: "",
            courseTypeStr: "",
            trainTypeStr: "",
            classifyIdStr: "",
            startTime: "",
            coursesCoverUrl: "",
            value1: "",
            value: "",
            courseCompanyVos: [],
            coursePositionsVos: [],
			industryName: ''
        };
    },
    created() {
        this.getDetails();
    },
    methods: {
        closeBox(e) {
            if (
                e.target.className ===
                "lookInfo flex-row-box flex-justify-center flex-cont-center"
            ) {
                this.show = false;
                this.$emit("refresh");
            }
        },
        closeOver() {
            this.show = false;
            this.$emit("refresh");
        },
        getDetails() {
            const that_ = this;
            that_.tableC = [];
            this.$axiosReq(courseDetails + this.row.id, null, null, "get").then(
                (res) => {
                    // //console.log(JSON.stringify(res))
                    that_.tableData = res.data.courseVideoDetailsVos;
                    that_.courseIntroduce = res.data.courseIntroduce;
                    that_.courseName = res.data.courseName;
                    that_.courseTypeStr = res.data.courseTypeStr;
                    that_.trainTypeStr = res.data.trainTypeStr;
                    that_.classifyIdStr = res.data.classifyIdStr;
                    that_.startTime = res.data.startTime;
                    that_.coursesCoverUrl = res.data.coursesCoverUrl;
                    that_.industryName = res.data.industryName ? res.data.industryName : '--';
                    if (
                        res.data.courseCompanyVos &&
                        res.data.courseCompanyVos.length > 0
                    ) {
                        that_.courseCompanyVos = res.data.courseCompanyVos;
                        that_.value = res.data.courseCompanyVos[0].id;
                    }
                    // if (
                    //     res.data.coursePositionsVos &&
                    //     res.data.coursePositionsVos.length > 0
                    // ) {
                    //     that_.coursePositionsVos = res.data.coursePositionsVos;
                    //     that_.value1 = res.data.coursePositionsVos[0].id;
                    // }
                }
            );
        },
    },
};
</script>

<style scoped>
.lookInfo {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
    font-size: 18px;
}

.lookInfoBox {
    width: 80%;
    height: 80%;
    background-color: #ffffff;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.lookInfoBox::-webkit-scrollbar {
    display: none;
}

.headerList {
    padding: 20px 0;
    /*border-bottom: 1px solid #c3c3c3;*/
    /*color: #5c8bff;*/
    /*font-weight: bold;*/
    font-size: 20px;
    font-weight: 500;
}

.lookInfoList {
    padding-top: 20px;
    font-size: 16px;
}

.lookInfoL {
    width: 25%;
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}
.flex-row-box{
    display: flex;
    align-items: center;
}

.lookInfoL::-webkit-scrollbar {
    display: none;
}

.exportTitle {
    width: 50px;
}

.infoImg {
    width: 65%;
    margin: 0 auto;
    height: 200px;
}

.infoImg img {
    display: block;
    width: 100%;
    height: 100%;
}

.infoName {
    width: 32%;
    padding-left: 20px;
    box-sizing: border-box;
}

.lookInfoR {
    margin: 0 auto;
    width: 73%;
}

.lookInfoTable {
    /* margin-top: 20px; */
    height: 600px;
    border: 1px solid #cccccc;
}

.demo-table-expand {
    font-size: 0;
}

.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}

.infoText {
    width: 65%;
    padding: 10px;
    background-color: #eeeeee;
    border-radius: 5px;
    box-sizing: border-box;
}
</style>
