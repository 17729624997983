<template>
  <el-dialog
    :visible.sync="show"
    width="45%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="16">
          <span class="title-name">回复</span>
        </el-col>
      </el-row>
    </div>
    <el-row class="mt-2">
      <el-col>
        <el-input
          v-model="form.reply"
          type="textarea"
          placeholder="请输入内容"
          :autosize="{ minRows: 5, maxRows: 5}"
          maxlength="250"
          show-word-limit
        />
      </el-col>
    </el-row>
    <div class="mt-2 flex justify-center">
      <el-button type="primary" size="small" @click="commit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { replyEvaluate } from '@/api/evaluate'
export default {
  name: 'Reply',
  components: {
  },
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    evaluate: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      form: {
        reply: ''
      }
    }
  },
  created() {
  },
  methods: {
    commit() {
      replyEvaluate({
        evaluateId: this.evaluate.evaluateId,
        reply: this.form.reply
      }).then(res => {
        if (res.data.code === 1) {
          this.$message.success(res.data.msg)
          this.close()
          this.$emit('get-list')
        }
      })
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.reply-div{
  min-height: 100px;
  border-bottom: 1px solid #3b3730;
}
</style>
