<template>
  <el-dialog
    :visible.sync="show"
    width="75%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <Reply
      v-if="replyShow.refresh"
      :dialog.sync="replyShow.show"
      :row="row"
      :evaluate="replyShow.evaluate"
      @refresh="refreshDialog"
      @get-list="getEvaluatesByCourseId"
    />
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ row.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex">
      <el-col>
        <span class="text-primary text-pad-right">时间</span>
        <el-date-picker
          v-model="condition.date"
          type="daterange"
          class="input searchInput"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          :unlink-panels="true"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00','23:59:59']"
          size="mini"
          @change="changeCondition"
        />
      </el-col>
      <el-col>
        <span class="text-primary text-pad-right">星级</span>
        <el-select v-model="condition.level" filterable clearable size="mini" class="input searchInput" @change="changeCondition">
          <el-option v-for="(v,i) in rate" :key="v.id" :value="v.id" :label="v.name" />
        </el-select>
      </el-col>
      <el-col class="flex">
        <span class="text-primary text-pad-right flex align-center" style="width: 47px">姓名</span>
        <el-input v-model="condition.searchName" class="searchInput" size="mini" suffix-icon="el-icon-search" placeholder="搜索" @input="changeCondition" />
      </el-col>
    </el-row>
    <el-row class="mt-2">
      <el-col>
        <div v-for="v in replyList" :key="v.evaluateId" class="flex justify-between reply-div">
          <div>
            <div>
              <p style="font-weight: bolder;font-size: 20px">{{ v.content }}</p>
            </div>
            <div class="flex justify-between" style="width: 300px">
              <div style="color: grey">{{ v.createtime }}</div>
              <div style="color: grey">{{ v.userName }}</div>
              <div style="font-weight: bolder">{{ row.title }}</div>
            </div>
            <div v-if="v.reply" class="pt-2">
              <div style="color: #5A8CFF">回复：{{ v.reply }}</div>
            </div>
          </div>
          <div>
            <p>
              <el-rate
                v-model="v.level"
                disabled-void-color="#fffff"
                void-icon-class="rate-icon"
                :colors="['#e8de05','#e8de05','#e8de05']"
                disabled
              />
            </p>
            <div class="mt-2 flex justify-center">
              <el-button v-if="!v.reply" type="primary" size="small" @click="openReply(v)">回复</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import Reply from './Reply'
import { quiz } from '@/api'
export default {
  name: 'Judge',
  components: {
    Reply
  },
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      replyShow: {
        show: false,
        refresh: false,
        info: {},
        evaluate: {}
      },
      condition: {
        date: '',
        level: '',
        searchName: ''
      },
      rate: [
        { id: '', name: '全部' },
        { id: 5, name: '五星' },
        { id: 4, name: '四星' },
        { id: 3, name: '三星' },
        { id: 2, name: '两星' },
        { id: 1, name: '一星' }
      ],
      replyList: []
    }
  },
  created() {
    this.getEvaluatesByCourseId()
  },
  methods: {
    getEvaluatesByCourseId() {
      const loading = this.$loading({
        lock: true,
        text: '正在查询!',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      getEvaluatesByCourseId({
        courseId: this.row.course_id,
        level: this.condition.level,
        sTime: this.condition.date ? this.condition.date[0] : '',
        eTime: this.condition.date ? this.condition.date[1] : '',
        searchName: this.condition.searchName
      }).then(res => {
        this.replyList = res.data
        loading.close()
      })
    },
    changeCondition() {
      this.getEvaluatesByCourseId()
    },
    openReply(v) {
      this.replyShow.show = true
      this.replyShow.refresh = true
      this.replyShow.evaluate = v
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    },
    refreshDialog() {
      this.replyShow.refresh = false
    }
  }
}
</script>

<style scoped>
.reply-div{
  min-height: 100px;
  border-bottom: 1px solid #F0F0F0;
}
</style>
