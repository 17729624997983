<template>
    <el-select
        size="small"
        v-bind="$attrs"
        v-on="$listeners"
        v-model="value"
        multiple
        filterable
        collapse-tags
        remote
        reserve-keyword
        placeholder="请搜索后选择公司名"
        :remote-method="remoteMethod"
    >
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
        >
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "SelectCompanyIds",
    data() {
        return {
            value: null,
            options: [],
        };
    },
    created() {
        this.remoteMethod();
    },

    methods: {
        async remoteMethod(query) {
            if (query !== "") {
                let { code, data } = await this.$api.get(
                    "oauth/server/escort/web/company",
                    {
                        params: {
                            currentPage: 1,
                            pageSize: 1000,
                            searchName: query,
                        },
                    }
                );
                if (code === 200) {
                    this.options = data.content;
                }
            } else {
                this.options = [];
            }
        },
    },
};
</script>

<style scoped></style>
