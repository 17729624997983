<template>
	<el-dialog :visible.sync="show" width="80%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row :gutter="20">
			<el-col :span="12">
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col :span="12">
								<el-select v-model="condition.topicType" size="mini" class="searchInput" clearable @change="getList">
									<el-option v-for="(v,i) in topicType" :key="i" :value="v.dictCode" :label="v.dictName" />
								</el-select>
							</el-col>
							<!-- <el-col>
                <el-input v-model="condition.topicTitle" size="mini" @input="getList">添加视频</el-input>
              </el-col> -->
							<el-col>
								<el-button type="warning" size="mini" @click="exportTableE">下载模板</el-button>
								<el-button type="warning" size="mini" @click="inputFlieClick">导入模板</el-button>
								<input ref="inputer" type="file" class="inputFlieNone" @change="getFile" />
								<el-button type="danger" size="mini" @click="remove({del:'batch'})" style="margin-left: 10px;">批量删除</el-button>
								<el-button type="primary" size="mini" @click="topicEnable" style="margin-left: 10px;">一键启用</el-button>
								<el-button type="primary" size="mini" @click="topicStop" style="margin-left: 10px;">一键停用</el-button>
							</el-col>
							<el-col :span="1">
								<el-button type="primary" size="mini" @click="getList">刷新</el-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="$headerStyle" :row-style="$rowStyle"
				 align="center" class="vxe-table-element" height="600" row-id="job_id" :sort-config="{remote: true}" :filter-config="{remote: true}"
				 :checkbox-config="{reserve: true}" :data="table.list" :loading="loading" @checkbox-all="handleSelectionChange"
				 @checkbox-change="handleSelectionChange">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="topicTitle" title="标题" show-overflow="title" />
					<vxe-table-column field="topicParsing" title="解析" show-overflow="title" />
					<vxe-table-column field="topicTypeName" title="类型" />
					<vxe-table-column field="statusName" title="状态" />
					<vxe-table-column title="是否启用">
						<template v-slot="{ row, rowIndex }">
							<el-switch ref="switch" v-model="row.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="0"
							 :inactive-value="1" @change="(v)=>{changeStatus(v,row)}" />
						</template>
					</vxe-table-column>
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-edit" @click.native="upd({title:'修改试题',addOr:'upd',id:row.id},row)">修改
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-col>
			<el-col :span="12">
				<el-form ref="ruleForm" label-width="110px" class="allForm">
					<el-form-item label="设置标题" :required="true" class="formInput">
						<el-input v-model="form.topicTitle" type="textarea" :autosize="{ minRows: 4}" />
					</el-form-item>
					<el-form-item label="试题解析" :required="true" class="formInput">
						<el-input v-model="form.topicParsing" type="textarea" :autosize="{ minRows: 4}" />
					</el-form-item>
					<el-form-item label="试题分数" :required="true" class="formInput">
						<el-input v-model="form.fraction" />
					</el-form-item>
					<el-form-item label="试题类型" :required="true" class="formInput">
						<el-select v-model="form.topicType">
							<el-option v-for="(v,i) in topicType" :key="i" :value="v.dictCode" :label="v.dictName" />
						</el-select>
					</el-form-item>
					<el-form-item label="试题选项" :required="true">
						<template v-if="form.topicType==0">
							<el-input v-for="(v, i) in radioList" :key="i" v-model="v.name" :disabled="info.addOr==='view'" placeholder="请输入内容">
								<template slot="prepend">
									<el-radio v-model="radio" :label="v.id" :disabled="info.addOr==='view'">{{ radioToUni(v.id) }}</el-radio>
								</template>
								<template v-if="info.addOr!=='view'" slot="append">
									<el-button v-if="v.id === 65" icon="el-icon-plus" @click="addOption" />
									<el-button v-else-if="v.id > 65" icon="el-icon-delete" @click="removeOption(i)" />
								</template>
							</el-input>
						</template>
						<template v-else-if="form.topicType==1">
							<el-input v-for="(v, i) in checkList" :key="i" v-model="v.name" :disabled="info.addOr==='view'" placeholder="请输入内容">
								<template slot="prepend">
									<el-checkbox-group v-model="checkbox">
										<el-checkbox :label="v.id">{{ radioToUni(v.id) }}</el-checkbox>
									</el-checkbox-group>
								</template>
								<template v-if="info.addOr!=='view'" slot="append">
									<el-button v-if="v.id === 65" icon="el-icon-plus" @click="addOption" />
									<el-button v-else-if="v.id > 65" icon="el-icon-delete" @click="removeOption(i)" />
								</template>
							</el-input>
						</template>
						<template v-else>
							<el-input v-for="(v, i) in judgeName" :key="i" v-model="v.name" disabled placeholder="请输入内容">
								<template slot="prepend">
									<el-radio v-model="judge" :label="v.key" :disabled="info.addOr==='view'" />
								</template>
							</el-input>
						</template>
					</el-form-item>
					<el-form-item label="正确选项">
						<span v-if="form.topicType==0" class="text-primary">{{ radioToUni(radio) }}</span>
						<template v-else-if="form.topicType==1">
							<span v-for="v in checkbox" :key="v" class="text-primary">{{ radioToUni(v) + '、' }}</span>
						</template>
						<span v-else class="text-primary">{{ judge }}</span>
					</el-form-item>
				</el-form>
			</el-col>
			<!--      <el-col :span="12">-->
			<!--        <div v-if="info.addOr!=='view'" class="dialog-footer" style="text-align: center;">-->
			<!--          <el-button type="primary" @click="nextCourse">下一步</el-button>-->
			<!--        </div>-->
			<!--      </el-col>-->
			<!--      <el-col :span="12">-->
			<!--        <div v-if="info.addOr!=='view'" class="dialog-footer" style="text-align: center;">-->
			<!--          <el-button type="primary" @click="commit">提交</el-button>-->
			<!--          <el-button type="info" @click="close">取消</el-button>-->
			<!--        </div>-->
			<!--      </el-col>-->
			<div class="dialog-footer" style="text-align: center;">
				<el-button type="primary" @click="commit">提交</el-button>
				<el-button type="info" @click="close">上一步</el-button>
			</div>
		</el-row>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" style="width: 200px" @click="closeAll">完成</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		topicVideo,
		topic,
		getDictParentCode,
		course
	} from '@/api'
	import {
		testNum
	} from "@/utils/validated";
	import {
		bytesToFile
	} from "@/utils/handleByte";
	export default {
		name: 'Operate',
		components: {},
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				loading: false,
				show: this.dialog,
				form: {
					topicParsing: '',
					topicTitle: '',
					topicType: '0',
					fraction: '',
					optionsDtos: []
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				topicType: [],
				radioList: [{
					id: 65,
					name: '',
					correct: 0
				}],
				radio: 65,
				condition: {
					topicType: '',
					topicTitle: ''
				},
				checkList: [{
					id: 65,
					name: '',
					correct: 0
				}],
				checkbox: [],
				judge: 'A',
				judgeName: [{
						name: '正确',
						id: '65',
						key: 'A'
					},
					{
						name: '错误',
						id: '66',
						key: 'B'
					}
				],
				inputFlieText: '',
				userIds: []
			}
		},
		created() {
			this.getDictParentCode()
			this.getList()
		},
		methods: {
			inputFlieClick() {
				var input = this.$refs.inputer;
				input.click();
			},
			getFile() {
				var that_ = this;
				var inputFile = that_.$refs.inputer.files[0];
				if (!inputFile) {
					that_.$message.error("请上传正确的文件");
					return false;
				}
				setTimeout(function() {
					var nowFile = that_.$refs.inputer;
					nowFile.value = "";
					nowFile.outerHTML = nowFile.outerHTML;
					that_.inputFlieText = "";
				}, 1000);
				that_.inputFlieUrl = new FormData();
				that_.inputFlieUrl.append("file", inputFile);
				this.$axiosReq(
					"/course/server/escort/web/v2.1/subject/excel/" + this.row.id,
					that_.inputFlieUrl,
					null,
					"post"
				).then((res) => {
					that_.$message.success("成功");
					that_.getList()
				});
			},
			exportTableE() {
				this.loading = true;
				this.$axiosReq("/course/server/escort/web/v2.1/subject/excel", null, null, "get")
					.then((res) => {
						bytesToFile(res.data.bytes, res.data.fileName);
					})
					.finally((e) => {
						this.loading = false;
					});
			},
			upd(v) {
				if (v.addOr === 'upd') {
					this.info.addOr = 'upd'
					this.$axiosReq(topic + v.id, null, null, 'get').then(res => {
						this.form = res.data
						this.form.topicType = res.data.topicType + ''
						this.getSubjectOption(this.form)
					})
				}
			},
			add() {
				this.info.addOr = 'add'
				this.form.topicTitle = ''
				this.form.topicParsing = ''
				this.form.fraction = ''
				this.form.topicType = '0'
				this.radio = 65
				this.radioList = [{
					id: 65,
					name: '',
					correct: 0
				}]
				this.checkList = [{
					id: 65,
					name: '',
					correct: 0
				}]
				this.judgeName = [{
						name: '正确',
						id: '65',
						key: 'A'
					},
					{
						name: '错误',
						id: '66',
						key: 'B'
					}
				]
				this.checkbox = []
			},
			getDictParentCode() {
				this.$axiosReq(getDictParentCode + 'SUBJECT_TYPE', null, null, 'get').then(res => {
					this.topicType = res.data
				})
			},
			getSubjectOption(v) {
				const option = v.topicOptionsVos
				this.radioList = []
				this.checkList = []
				this.checkbox = []
				for (const i of option) {
					if (v.topicType == 0) {
						this.radioList.push({
							id: i.options.charCodeAt(),
							name: i.remarks,
							correct: i.isCorrect
						})
						if (i.isCorrect === 0) {
							this.radio = i.options.charCodeAt()
						}
					} else if (v.topicType == 1) {
						this.checkList.push({
							id: i.options.charCodeAt(),
							name: i.remarks,
							correct: i.isCorrect
						})
						if (i.isCorrect === 0) {
							this.checkbox.push(i.options.charCodeAt())
						}
					} else if (v.topicType == 2) {
						if (i.options === 'A' && i.isCorrect === 0) {
							this.judge = 'A'
						} else if (i.options === 'B' && i.isCorrect === 0) {
							this.judge = 'B'
						}
					}
				}
			},
			getContent(v) {
				this.form.content_text = v.noHtml
			},
			// 转数字为字母
			radioToUni(v) {
				return String.fromCharCode(v)
			},
			// 增加选项
			addOption() {
				if (this.form.topicType == 0) {
					this.radioList.push({
						id: this.radioList[this.radioList.length - 1].id + 1,
						name: ''
					})
				} else {
					this.checkList.push({
						id: this.checkList[this.checkList.length - 1].id + 1,
						name: ''
					})
				}
			},
			// 删除选项
			removeOption(i) {
				this.radioList.splice(i, 1);

				if (this.form.topicType == 0) {

				} else if (this.form.topicType == 1) {
					if (this.checkList.length > 1) {
						this.checkbox.splice(i, 1);
						this.checkList.splice(i, 1);
					} else {
						this.checkbox.splice(0, 1);
					}
					// if (this.checkList.length > 1) this.checkbox.splice(i, 1)
					// else this.checkbox.splice(0, 1)
				}

				if (this.radioList.length === 1) {
					this.radio = 65
				}
				this.radioList = this.radioList.map((v, i, e) => {
					return {
						id: 65 + i,
						name: v.name
					}
				})
			},
			getList() {
				this.loading = true
				this.$axiosReq(topic, null, {
					topicType: this.condition.topicType,
					videoId: this.row.id,
					topicTitle: this.condition.topicTitle,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			changeStatus(v, row) {
				this.$axiosReq(topic + row.id, null, null, 'patch').then(res => {
					this.$message.success(res.msg)
				}).finally(() => {
					this.getList()
				})
			},
			remove(info, row) {
				const id = []
				if (info.del === 'batch') {
					const arr = this.$refs.table.getCheckboxRecords()
					const arr1 = this.$refs.table.getCheckboxReserveRecords()
					const batchDel = arr.concat(arr1)
					for (const i of batchDel) {
						id.push(i.id)
					}
					if (id.length === 0) {
						this.$message.error('请勾选！！！')
						return
					}
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$axiosReq(topic, null, {
							ids: id.join()
						}, 'delete').then(res => {
							this.$message.success(res.msg)
							this.getList()
						})
					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				} else {
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$axiosReq(topic + row.id, null, null, 'delete').then(res => {
							this.$message.success(res.msg)
							this.getList()
						})
					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				}
			},
			handleSelectionChange(val) {
				const data = val.records;
				this.userIds = [];
				for (let i = 0; i < data.length; i++) {
					this.userIds.push(data[i].id);
				}
			},
			topicEnable() {
				if (this.userIds.length < 1) {
					this.$message.error("请选择试题");
					return false;
				}
				if (this.userIds.length > 20) {
					this.$message.error("最多一次性启用20个试题");
					return false;
				}
				this.$axiosReq('/course/server/escort/web/topic/enable', this.userIds, null, 'put').then(res => {
					this.$message.success(res.msg)
					this.getList()
				})
			},
			topicStop() {
				if (this.userIds.length < 1) {
					this.$message.error("请选择试题");
					return false;
				}
				if (this.userIds.length > 20) {
					this.$message.error("最多一次性启用20个试题");
					return false;
				}
				this.$axiosReq('/course/server/escort/web/topic/stop', this.userIds, null, 'put').then(res => {
					this.$message.success(res.msg)
					this.getList()
				})
			},
			// 提交添加
			commit() {
				if (this.$textNull(this.form.topicTitle)) {
					this.$message.error('请填写标题！')
					return
				} else if (this.$textNull(this.form.topicParsing)) {
					this.$message.error('请填写解析！')
					return
				} else if (this.$textNull(this.form.fraction)) {
					this.$message.error('请填写分数！')
					return
				} else if (testNum(this.form.fraction)) {
					this.$message.error('请填写正确分数！')
					return
				}
				const options = []
				if (this.form.topicType == 0) {
					for (const i in this.radioList) {
						if (this.$textNull(this.radioList[i].name)) {
							this.$message.error('请填写选项内容！')
							return
						}
						options.push({
							options: this.radioToUni(this.radioList[i].id),
							remarks: this.radioList[i].name,
							isCorrect: this.radioList[i].id === this.radio ? 0 : 1
						})
					}
				} else if (this.form.topicType == 1) {
					for (const i in this.checkList) {
						if (this.$textNull(this.checkList[i].name)) {
							this.$message.error('请填写选项内容！')
							return
						}
						if (this.checkbox.length === 0) {
							this.$message.error('请勾选至少一项！')
							return
						}
						options.push({
							options: this.radioToUni(this.checkList[i].id),
							remarks: this.checkList[i].name,
							isCorrect: 1
						})
						if (this.form.topicType == 1) {
							for (const j of this.checkbox) {
								if (this.checkList[i].id === j) {
									options[i].isCorrect = 0
									break
								}
							}
						}
					}
				} else {
					for (const i of this.judgeName) {
						options.push({
							options: this.radioToUni(i.id),
							remarks: i.name,
							isCorrect: i.key === this.judge ? 0 : 1
						})
					}
				}
				this.form.optionsDtos = options
				if (this.info.addOr === 'add') {
					this.$axiosReq(topicVideo + this.row.id, this.form, null, 'post').then(res => {
						this.$message.success(res.msg)
						// this.close()
						// this.$emit('get-list')
						this.getList()
						this.add()
					})
				} else {
					this.$axiosReq(topic + this.form.id, this.form, null, 'put').then(res => {
						this.$message.success(res.msg)
						// this.close()
						// this.$emit('get-list')
						this.getList()
						this.add()
					})
				}
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			}, // 关闭模态框
			closeAll() {
				this.show = false
				this.$emit('refreshAll')
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			}
		}
	}
</script>

<style scoped>
	.inputFlieNone {
		width: 150px;
		height: 40px;
		display: none;
	}

	.inputText {
		font-size: 16px;
	}
</style>
