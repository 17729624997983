<template>
    <el-dialog
        :visible.sync="show"
        top="2vh"
        width="80%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <div slot="title" class="header-title">
            <el-row :gutter="5">
                <el-col :span="24">
                    <span class="title-name">{{ info.title }}</span>
                </el-col>
            </el-row>
        </div>
        <el-card>
            <el-row type="flex" justify="space-between" :gutter="50"
                ><el-col>
                    <span class="text-primary text-pad-right">公司</span>
                    <el-select
                        v-model="companyId"
                        filterable
                        collapse-tags
                        placeholder="请选择"
                        size="mini"
                        clearable
                        @change="
                            () => {
                                table.currentPage = 1;
                                departId = '';
                                positionId = '';
                                labelId = '';
                                getDepart();
                                getPosition();
                                getLabels();
                                getList();
                            }
                        "
                    >
                        <el-option
                            v-for="item in company"
                            :key="item.companyId"
                            :label="item.companyName"
                            :value="item.companyId"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col>
                    <span class="text-primary text-pad-right">部门</span>
                    <el-select
                        clearable
                        filterable
                        v-model="departId"
                        class="input searchInput"
                        size="mini"
                        @change="
                            () => {
                                table.currentPage = 1;
                                getList();
                            }
                        "
                    >
                        <el-option
                            v-for="(v, i) in depart"
                            :key="i"
                            :value="v.id"
                            :label="v.departName"
                        />
                    </el-select>
                </el-col>
                <el-col>
                    <span class="text-primary text-pad-right">岗位</span>
                    <el-select
                        clearable
                        filterable
                        v-model="positionId"
                        class="input searchInput"
                        size="mini"
                        @change="
                            () => {
                                table.currentPage = 1;
                                getList();
                            }
                        "
                    >
                        <el-option
                            v-for="(v, i) in position"
                            :key="i"
                            :value="v.id"
                            :label="v.positionName"
                        />
                    </el-select>
                </el-col>

                <el-col>
                    <span class="text-primary text-pad-right">标签</span>
                    <el-select
                        clearable
                        filterable
                        v-model="labelId"
                        class="input searchInput"
                        size="mini"
                        @change="
                            () => {
                                table.currentPage = 1;
                                getList();
                            }
                        "
                    >
                        <el-option
                            v-for="(v, i) in labelList"
                            :key="i"
                            :value="v.id"
                            :label="v.labelName"
                        />
                    </el-select>
                </el-col>
                <!-- <el-col>
                    <span class="text-primary text-pad-right">标签</span>
                    <el-select
                        clearable
                        v-model="labelId"
                        class="input searchInput"
                        size="mini"
                        @change="getList"
                    >
                        <el-option
                            v-for="(v, i) in labelList"
                            :key="i"
                            :value="v.id"
                            :label="v.labelName"
                        />
                    </el-select>
                </el-col> -->
                <el-col>
                    <span class="text-primary text-pad-right">姓名</span>
                    <el-input
                        v-model="condition.search"
                        class="input searchInput"
                        size="mini"
                        style="width: 200px"
                        suffix-icon="el-icon-search"
                        placeholder="搜索"
                        @input="
                            () => {
                                table.currentPage = 1;
                                searchInput();
                            }
                        "
                    />
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 20px">
            <el-row>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex">
                            <el-col :span="19">
                                <vxe-button @click="searchInput('1')"
                                    >刷新</vxe-button
                                >
                            </el-col>
                            <el-col :span="5">
                                <vxe-button @click="batchBind"
                                    >批量关联</vxe-button
                                >
                                <vxe-button @click="batchUnbind"
                                    >批量取消关联</vxe-button
                                >
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="600"
                    row-id="notice_id"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :data="table.list"
                    :loading="loading"
                    @checkbox-all="handleSelectionChange"
                    @checkbox-change="handleSelectionChange"
                >
                    <vxe-table-column type="checkbox" width="60" fixed="left" />
                    <vxe-table-column type="seq" width="60" />
                    <vxe-table-column field="realName" title="姓名" />
                    <vxe-table-column field="phone" title="电话号码" />
                    <vxe-table-column
                        field="idCard"
                        title="身份证号码"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="companyName"
                        title="公司名称"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="label"
                        title="标签"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="positionName"
                        title="岗位名称"
                        show-overflow="title"
                    />
                    <vxe-table-column field="isRelatedName" title="关联状态" />
                    <vxe-table-column title="设置关联">
                        <template v-slot="{ row, rowIndex }">
                            <el-switch
                                ref="switch"
                                v-model="row.isRelated"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                :active-value="0"
                                :inactive-value="1"
                                @change="
                                    (v) => {
                                        changeStatus(v, row);
                                    }
                                "
                            />
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100, 200]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-row>
        </el-card>
        <el-row type="flex" justify="center" style="margin-top: 20px">
            <el-col :span="5">
                <div class="listBtn1" @click="isBoxShow = true">上一步</div>
            </el-col>
            <el-col :span="5">
                <div class="listBtn" @click="close">完成</div>
            </el-col>
        </el-row>
        <div class="boxShow" v-if="isBoxShow">
            <div class="boxShowBox">
                <el-row>
                    <el-col :span="1">
                        <div style="width: 1px; height: 1px"></div>
                    </el-col>
                    <el-col :span="22">
                        <div
                            class="text-center"
                            style="
                                font-size: 20px;
                                padding: 15px 0;
                                font-weight: 500;
                            "
                        >
                            配置企业
                        </div>
                    </el-col>
                    <el-col :span="1">
                        <i
                            class="el-icon-close"
                            style="font-size: 28px; padding-top: 15px"
                            @click="close"
                        ></i>
                    </el-col>
                </el-row>
                <div style="width: 85%; margin: 0 auto; padding-top: 15px">
                    <el-form ref="ruleForm" label-width="110px" class="allForm">
                        <el-form-item label="所属行业" :required="true">
                            <el-select
                                v-model="parentId"
                                placeholder="请选择"
                                @change="getCompanyListAll"
                            >
                                <el-option
                                    v-for="item in parentCode"
                                    :key="item.dictCode"
                                    :label="item.dictName"
                                    :value="item.dictCode"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-form ref="ruleForm" label-width="110px" class="allForm">
                        <el-form-item label="配课区域">
                            <el-cascader
                                v-model="cityCode"
                                :options="optionsCity"
                                :props="propsCity"
                                filterable
                                :show-all-levels="false"
                                @visible-change="getCompanyListAll"
                                clearable
                            ></el-cascader>
                        </el-form-item>
                    </el-form>
                    <el-form ref="ruleForm" label-width="110px" class="allForm">
                        <el-form-item label="所属公司" :required="true">
                            <el-row>
                                <el-col :span="22">
                                    <el-select
                                        v-model="companyIds"
                                        filterable
                                        multiple
                                        collapse-tags
                                        placeholder="请选择"
                                        @change="selectChange"
                                    >
                                        <el-option
                                            v-for="item in company"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="2" style="padding-left: 10px">
                                    <el-checkbox
                                        v-model="checked"
                                        @change="clickChecked"
                                        >全选</el-checkbox
                                    >
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="allBtn" @click="companyCourse">下一步</div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { sysUserCourseId, courseUser, areaTreeAll, companyAll } from "@/api";

export default {
    name: "SetUser",
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            loading: false,
            show: this.dialog,
            condition: {
                search: "",
                companyId: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            position: [],
            depart: [],
            positionId: "",
            departId: "",
            company: [],
            companyId: "",
            companyIds: [],
            propsCity: {
                multiple: true,
                children: "children",
                label: "areaName",
                value: "areaCode",
                emitPath: false,
            },
            optionsCity: [],
            cityCode: [],
            parentCode: [],
            parentId: "",
            isBoxShow: true,
            userIds: [],
            checked: false,
            labelList: [],
            labelId: "",
            companyNameVos: [],
        };
    },
    created() {
        console.log(this.row);
        let pageSizeS = localStorage.getItem("pageSize");
        this.getParentCode();
        this.getAreaTreeAll();
        this.getCompanyCourse();
        if (pageSizeS) {
            this.table.pageSize = Number(pageSizeS);
        }
    },
    methods: {
        rowStyle,
        headerStyle,
        searchInput(v) {
            this.userIds = [];
            this.getList();
        },
        changeCondition() {
            this.table.currentPage = 1;
            this.getList();
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                sysUserCourseId + this.row.id,
                null,
                {
                    label: this.labelId,
                    positionId: this.positionId,
                    departId: this.departId,
                    commonField: this.condition.search, //姓名、电话、身份证查询字段
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                    companyIds: this.companyIds + "", //公司id集合
                    companyId: this.companyId,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        handleSizeChange(pageSize) {
            localStorage.setItem("pageSize", JSON.stringify(pageSize));
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.userIds = [];
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.userIds = [];
            this.getList();
        },
        handleSelectionChange(val) {
            const data = val.records;
            this.userIds = [];
            for (let i = 0; i < data.length; i++) {
                this.userIds.push(data[i].id);
            }
        },
        changeStatus(v, row) {
            this.$axiosReq(
                courseUser + this.row.id + "/userId/" + row.id,
                null,
                null,
                "patch"
            )
                .then((res) => {
                    this.$message.success(res.msg);
                    this.getList();
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        batchUnbind(v, row) {
            if (!this.userIds || this.userIds.length < 1) {
                this.$message.error("请选择人员");
                return false;
            }
            this.$axiosReq(
                "/course/server/escort/web/courseUser/batch/unbind",
                {
                    courseId: this.row.id,
                    userIds: this.userIds,
                },
                null,
                "post"
            )
                .then((res) => {
                    this.$message.success(res.msg);
                    this.userIds = [];
                    this.getList();
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        batchBind(v, row) {
            if (!this.userIds || this.userIds.length < 1) {
                this.$message.error("请选择人员");
                return false;
            }
            this.$axiosReq(
                "/course/server/escort/web/courseUser/batch/bind",
                {
                    courseId: this.row.id,
                    userIds: this.userIds,
                },
                null,
                "post"
            )
                .then((res) => {
                    this.$message.success(res.msg);
                    this.userIds = [];
                    this.getList();
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
        getParentCode() {
            this.loading = true;
            this.$axiosReq(
                "/data/server/escort/web/dict/parentCode/INDUSTRY_TYPE",
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.parentCode = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getAreaTreeAll() {
            this.loading = true;
            this.$axiosReq(areaTreeAll, null, null, "get")
                .then((res) => {
                    this.optionsCity = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getCompanyListAll(e) {
            let that_ = this;
            this.$axiosReq(
                companyAll,
                {
                    areaCodes: that_.cityCode,
                    industry: that_.parentId,
                },
                null,
                "post"
            ).then((res) => {
                that_.company = res.data;

                if (that_.companyNameVos && that_.companyNameVos.length > 0) {
                    that_.companyNameVos.forEach((item) => {
                        if (that_.company.includes(item)) {
                            that_.companyIds.push(item.companyId);
                        } else {
                            // that_.companyIds.push(item.companyId);
                            // that_.company.push(
                            //     Object.assign({ disabled: true }, item)
                            // );
                        }
                    });
                    if (that_.companyIds.length === that_.company.length) {
                        that_.checked = true;
                    }
                }
            });
        },
        companyCourse() {
            let that_ = this;
            if (!that_.parentId) {
                this.$message.error("请选择行业");
                return false;
            }
            if (!that_.companyIds || that_.companyIds.length < 1) {
                this.$message.error("请选择公司");
                return false;
            }
            this.$axiosReq(
                "/course/server/escort/web/course/company/courseId/" +
                    that_.row.id,
                {
                    companyIds: that_.companyIds,
                    industry: that_.parentId,
                },
                null,
                "post"
            ).then((res) => {
                that_.isBoxShow = false;
                that_.getPosition();
                that_.getDepart();
                that_.getLabels();
                that_.getList();
            });
        },
        getCompanyCourse() {
            let that_ = this;
            this.$axiosReq(
                "/course/server/escort/web/course/company/courseId/" +
                    that_.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                const companyNameVos = res.data.companyNameVos;
                that_.parentId = res.data.industry ? res.data.industry : "";
                if (res.data.areaCodes && res.data.areaCodes.length > 0) {
                    that_.cityCode = res.data.areaCodes;
                    that_.cityCode.sort(function (a, b) {
                        return a - b;
                    });
                }
                if (Array.isArray(companyNameVos)) {
                    console.log(companyNameVos);
                    this.companyIds = companyNameVos.map(
                        (item) => item.companyId
                    );
                    console.log(this.companyIds);
                }
                that_.companyNameVos = res.data.companyNameVos;
                that_.getCompanyListAll();
            });
        },
        async getPosition() {
            if (this.companyId) {
                let { code, data } = await this.$api.get(
                    `/oauth/server/escort/web/position/companyId/${this.companyId}`
                );
                if (code == 200) {
                    this.position = data;
                }
            } else {
                this.position = [];
            }

            // let that_ = this;
            // this.$axiosReq(
            //     "/oauth/server/escort/web/position/courseId/" + that_.row.id,
            //     null,
            //     null,
            //     "get"
            // ).then((res) => {
            //     that_.position = res.data;
            // });
        },
        async getDepart() {
            if (this.companyId) {
                let {
                    code,
                    data,
                } = await this.$api.post(
                    "/oauth/server/escort/web/depart/companyDeparts",
                    [this.companyId]
                );
                if (code == 200) {
                    this.depart = data;
                }
            } else {
                this.depart = [];
            }

            // let that_ = this;
            // this.$axiosReq(
            //     "/oauth/server/escort/web/depart/courseId/" + that_.row.id,
            //     null,
            //     null,
            //     "get"
            // ).then((res) => {
            //     that_.depart = res.data;
            // });
        },
        async getLabels() {
            if (this.companyId) {
                let { code, data } = await this.$api.get(
                    "/oauth/server/escort/web/getlabel",
                    {
                        params: {
                            companyId: this.companyId,
                        },
                    }
                );
                if (code == 200) {
                    this.labelList = data;
                }
            } else {
                this.labelList = [];
            }
            // let that_ = this;
            // this.$axiosReq(
            //     "/course/server/escort/web/course/labels/" + that_.row.id,
            //     null,
            //     null,
            //     "get"
            // ).then((res) => {
            //     that_.labelList = res.data;
            // });
        },
        clickChecked() {
            let that_ = this;
            if (this.checked) {
                this.companyIds = [];
                this.company.forEach((item) => {
                    that_.companyIds.push(item.companyId);
                });
            } else {
                this.companyIds = [];
            }
        },
        selectChange() {
            let that_ = this;
            if (that_.companyIds.length === that_.company.length) {
                that_.checked = true;
            } else {
                that_.checked = false;
            }
        },
    },
};
</script>

<style scoped>
.boxShow {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.boxShowBox {
    width: 50%;
    background-color: #ffffff;
    height: 100%;
    /*position: relative;*/
    /*top:10vh;*/
    margin: 0 auto;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.boxShowBox::-webkit-scrollbar {
    display: none;
}

.allBtn {
    width: 260px;
    height: 35px;
    line-height: 35px;
    background-color: #007aff;
    color: #ffffff;
    text-align: center;
    border-radius: 8px;
    margin: 50px auto;
}

.listBtn {
    width: 260px;
    height: 35px;
    line-height: 35px;
    background-color: #007aff;
    color: #ffffff;
    text-align: center;
    border-radius: 8px;
}

.listBtn1 {
    width: 260px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #cccccc;
    text-align: center;
    border-radius: 8px;
}
</style>
