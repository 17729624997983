<template>
	<el-dialog :visible.sync="show" width="80%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<Topic v-if="next.topicRefresh" :dialog.sync="next.topicShow" :row="next.row" :info="next.info" @refresh="refreshDialog"
		 @refreshAll="refreshDialogAll" />
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row :gutter="20">
			<el-col :span="12">
				<el-form ref="ruleForm" label-width="110px" class="allForm" :disabled="info.addOr === 'view'">
					<el-form-item label="视频标题" :required="true">
						<el-input v-model="form.videoTitle" class="formInput" />
					</el-form-item>
					<el-form-item label="视频长度" :required="true" class="formInput">
						<el-time-picker v-model="videoNum" placeholder="选择时间" @blur="videoLengthBlur"></el-time-picker>
						<!-- <el-input v-model="form.videoLength" class="formInput">
						  <template slot="append">时长(秒数)</template>
						</el-input> -->
					</el-form-item>
					<el-form-item label="考试时长" class="formInput" :required="true">
						<el-time-picker v-model="testTimeNum" placeholder="选择时间" @blur="testTimeBlur"></el-time-picker>
						<!-- <el-input v-model="form.testTime" class="formInput">
							<template slot="append">时长(秒数)</template>
						</el-input> -->
					</el-form-item>
					<el-form-item label="考试次数" class="formInput">
						<el-input v-model="form.testCount" class="formInput" type="number" />
					</el-form-item>
					<el-form-item label="随机题目数" class="formInput">
						<el-input v-model="form.randomTopic" class="formInput" type="number" />
					</el-form-item>
					<el-form-item label="及格分比率" class="formInput">
						<el-input v-model="form.passRatio" class="formInput" type="number" />
					</el-form-item>
					<el-form-item label="视频路径" :required="true" class="formInput">
						<el-input v-model="form.videoUrl" class="formInput">
							<template slot="append" />
						</el-input>
					</el-form-item>
					<el-form-item label="视频网络地址（流畅格式）" class="formInput">
						<el-input v-model="form.smoothUrl" class="formInput" />
					</el-form-item>
					<el-form-item label="视频网络地址（标清格式）" class="formInput">
						<el-input v-model="form.standardDefinitionUrl" class="formInput" />
					</el-form-item>
					<el-form-item label="视频网络地址（高清格式）" class="formInput">
						<el-input v-model="form.hdUrl" class="formInput" />
					</el-form-item>
					<el-form-item label="授课老师" :required="true" class="formInput">
						<el-input v-model="form.instructor" class="formInput w-100" />
					</el-form-item>
					<el-form-item label="视频简介" :required="true" class="formInput">
						<el-input v-model="form.remarks" class="formInput" type="textarea" :autosize="{ minRows: 4}" maxlength="200" />
					</el-form-item>
					<el-form-item label="视频封面" :required="true">
						<el-upload ref="upload" class="upload-demo" drag :action="
			    $srcUrl+upFile" :headers="{
			    Authorization:$token
			  }"
						 list-type="picture" :file-list="fileList" :auto-upload="true" :limit="1" :on-change="change" :on-success="uploadFile">
							<i class="el-icon-receiving" style="font-size: 80px;" />
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>，只支持传一张图片</div>
						</el-upload>
					</el-form-item>
				</el-form>
				<div v-if="info.addOr!=='view'" class="dialog-footer" style="text-align: center;">
					<el-button type="primary" @click="commit" style="margin-left: 100px;">保存视频</el-button>
					<!-- <el-button type="info" @click="close">取消</el-button> -->
				</div>
			</el-col>
			<el-col :span="12">
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-input v-model="condition.videoTitle" size="mini" @input="getList" />
							</el-col>
							<el-col>
								<!-- <el-button type="primary" size="mini" @click="add">重新添加</el-button> -->
								<span @click="add" style="background-color: #007AFF;border-radius: 5px;padding: 5px 10px;margin: 0 10px;color: #FFF;cursor: pointer;">重新添加</span>
								<el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>
							</el-col>
							<el-col :span="2">
								<el-button type="primary" size="mini" @click="getList">刷新</el-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="$headerStyle" :row-style="$rowStyle"
				 align="center" class="vxe-table-element" height="600" row-id="id" :sort-config="{remote: true}" :filter-config="{remote: true}"
				 :checkbox-config="{reserve: true}" :data="table.list" :loading="loading" @checkbox-all="handleSelectionChange" @checkbox-change="handleSelectionChange">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column field="videoTitle" title="视频标题" />
					<vxe-table-column field="videoLengthFormat" title="视频时长" />
					<vxe-table-column field="instructor" title="授课老师" />
					<vxe-table-column field="coverUrl" title="视频封面">
						<template v-slot="{ row, rowIndex }">
							<el-popover placement="right" trigger="hover">
								<img :src="$fileUrl+row.coverUrl" alt="" style="width: 500px;height: 500px">
								<img slot="reference" :src="$fileUrl+row.coverUrl" alt="" style="width: 50px;height: 50px">
							</el-popover>
						</template>
					</vxe-table-column>
					<vxe-table-column field="remarks" title="视频简介" show-overflow="title" />
					<vxe-table-column field="statusName" title="状态" />
					<vxe-table-column title="是否启用">
						<template v-slot="{ row, rowIndex }">
							<el-switch ref="switch" v-model="row.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="0"
							 :inactive-value="1" @change="(v)=>{changeStatus(v,row)}" />
						</template>
					</vxe-table-column>
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-edit" @click.native="upd({title:'修改视频',addOr:'upd',id:row.id},row)">修改
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-edit" @click.native="nextCourse(row)">配置试题
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="table.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
				 <div v-if="info.addOr!=='view'" class="dialog-footer" style="text-align: center;margin-top: 50px;">
				 	<el-button type="primary" @click="closeNow">上一步</el-button>
				 	<el-button type="primary" @click="close">提交课程</el-button>
				 </div>
			</el-col>
			<!--      <el-col :span="12">-->
			<!--        <div v-if="info.addOr!=='view'" class="dialog-footer" style="text-align: center;">-->
			<!--          <el-button type="primary" @click="commit">提交</el-button>-->
			<!--          <el-button type="info" @click="close">取消</el-button>-->
			<!--        </div>-->
			<!--      </el-col>-->
		</el-row>
	</el-dialog>
</template>

<script>
	import {
		topic,
		upFile,
		video,
		videoCourse
	} from '@/api'
	import {
		uploadAccept
	} from '@/utils/uploadAccept'
	import Topic from '../topic'
	import {
		testNum
	} from "@/utils/validated";
	export default {
		name: 'Operate',
		components: {
			Topic
		},
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				loading: false,
				videoNum: new Date(2021, 1, 1, 0, 0),
				testTimeNum: new Date(2021, 1, 1, 0, 0),
				form: {
					videoTitle: '',
					videoLength: 0,
					videoLengthFormat: '',
					videoUrl: '',
					passRatio: 100,
					smoothUrl: '',
					standardDefinitionUrl: '',
					hdUrl: '',
					instructor: '',
					testTime: 0,
					testTimeStr: '',
					testCount: 1,
					remarks: '',
					courseId: '',
					coverUrl: '',
					randomTopic: 1,
					sort: '',
					startLength: '',
					endLength: ''
				},
				userIds: [],
				fileList: [],
				condition: {
					videoTitle: ''
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				next: {
					show: false,
					refresh: false,
					topicRefresh: false, // 每次刷新DOM
					topicShow: false,
					info: {}
				},
				upFile
			}
		},
		created() {
			this.getList()
		},
		methods: {
			handleSelectionChange(val) {
				const data = val.records;
				this.userIds = [];
				for (let i = 0; i < data.length; i++) {
					this.userIds.push(data[i].id);
				}
			},
			upd(v) {
				if (v.addOr !== 'add') {
					this.info.addOr = v.addOr
					this.fileList = []
					this.$axiosReq(video + v.id, null, null, 'get').then(res => {
						this.form = res.data;
						var a1 = this.form.videoLengthFormat.split(':')[0]
						var a2 = this.form.videoLengthFormat.split(':')[1]
						var a3 = this.form.videoLengthFormat.split(':')[2]
						var a4 = this.form.testTimeStr.split(':')[0]
						var a5 = this.form.testTimeStr.split(':')[1]
						var a6 = this.form.testTimeStr.split(':')[2]
						this.videoNum = new Date(2021, 1, 1,a1,a2,a3);
						this.testTimeNum = new Date(2021, 1, 1,a4,a5,a6);
						this.fileList.push({
							url: this.$fileUrl + this.form.coverUrl
						})
					})
				}
			},
			add() {
				this.form = {
					videoTitle: '',
					videoLength: 0,
					videoLengthFormat: '',
					videoUrl: '',
					passRatio: 100,
					smoothUrl: '',
					standardDefinitionUrl: '',
					hdUrl: '',
					instructor: '',
					testTime: 0,
					testTimeStr: '',
					testCount: 1,
					remarks: '',
					courseId: '',
					coverUrl: '',
					randomTopic: 1,
					sort: '',
					startLength: '',
					endLength: '',
				}
				this.videoNum = new Date(2021, 1, 1, 0, 0);
				this.testTimeNum = new Date(2021, 1, 1, 0, 0);
				this.info.addOr = 'add'
				this.fileList = [];
			},
			change(file, fileList) {
				const fileSize = file.size / 1024 / 1024 < 500
				if (!fileSize) {
					this.$message.error('文件大小不能超过500MB！！！')
					return false
				}
				let flag = false
				flag = uploadAccept(file, '图片')
				if (flag) {} else {
					fileList.splice(-1, 1)
				}
			},
			uploadFile(res) {
				if (res.code !== 200) {
					this.$message.error(res.msg)
					this.$refs.upload.clearFiles()
				} else if (res.code === 401) {
					sessionStorage.clear()
					localStorage.clear()
					window.location.reload()
				}
				this.form.coverUrl = res.data.filePath
			},
			changeStatus(v, row) {
				this.$axiosReq(video + row.id, null, null, 'patch').then(res => {
					this.$message.success(res.msg)
				}).finally(() => {
					this.getList()
				})
			},
			getList() {
				this.loading = true
				this.$axiosReq(video, null, {
					courseId: this.row.id,
					videoTitle: this.condition.videoTitle,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			// 下一步
			nextCourse(v) {
				this.next.row = {
					id: v.id
				}
				this.next.info = {
					addOr: 'add'
				}
				this.next.topicRefresh = true
				this.next.topicShow = true
			},
			remove(info, row) {
				// const id = []
				if (info.del === 'batch') {
					// const arr = this.$refs.table.getCheckboxRecords()
					// const arr1 = this.$refs.table.getCheckboxReserveRecords()
					// const batchDel = arr.concat(arr1)
					// for (const i of batchDel) {
					// 	id.push(i.id)
					// }
					if (this.userIds.length === 0) {
						this.$message.error('请勾选！！！')
						return
					}
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$axiosReq(video, null, {
							ids: this.userIds.join()
						}, 'delete').then(res => {
							this.$message.success(res.msg);
							this.userIds = [];
							this.getList()
						})
					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				} else {
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$axiosReq(video + row.id, null, null, 'delete').then(res => {
							this.$message.success(res.msg)
							this.getList()
						})
					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				}
				this.add();
			},
			// 提交添加
			commit() {
				if (this.$textNull(this.form.videoTitle)) {
					this.$message.error('请填写视频标题！')
					return
				} else if (this.$textNull(this.form.videoLength)) {
					this.$message.error('请填写视频长度！')
					return
				} else if (testNum(this.form.videoLength)) {
					this.$message.error('请填写正确视频长度！')
					return
				} else if (this.form.videoLength <= 0 || this.form.videoLength > 10800) {
					this.$message.error('视频长度最小为1秒，最大不能超过10800秒！')
					return
				} else if (this.$textNull(this.form.testTime)) {
					this.$message.error('请输入考试时长！')
					return
				} else if (testNum(this.form.testTime)) {
					this.$message.error('请输入正确考试时长！')
					return
				} else if (this.form.testTime <= 0 || this.form.testTime > 10800) {
					this.$message.error('考试时长最小为1秒，最大不能超过10800秒！')
					return
				} else if (this.form.testCount < 1 || this.form.testCount > 100) {
					this.$message.error('考试次数（最小为1最大为100）')
					return
				} else if (this.form.randomTopic < 1 || this.form.randomTopic > 100) {
					this.$message.error('随机题目数（最小为1最大为100）')
					return
				} else if (this.form.passRatio < 0 || this.form.passRatio > 100) {
					this.$message.error('及格比分率（最小为0最大为100）')
					return
				} else if (testNum(this.form.randomTopic)) {
					this.$message.error('请输入正确随机题目数！')
					return
				} else if (this.$textNull(this.form.videoUrl)) {
					this.$message.error('请填写视频地址！')
					return
				} else if (this.$textNull(this.form.instructor)) {
					this.$message.error('请填写授课老师！')
					return
				} else if (this.$textNull(this.form.remarks)) {
					this.$message.error('请填写视频简介！')
					return
				} else if (this.$textNull(this.form.coverUrl)) {
					this.$message.error('请选择视频封面！')
					return
				}
				const loading = this.$loading({
					lock: true,
					text: '正在添加数据请稍后！！！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				if (this.info.addOr === 'add') {
					this.$axiosReq(videoCourse + this.row.id, null, this.form, 'post').then(res => {
						this.$message.success(res.msg);
						this.form = {
							videoTitle: '',
							videoLength: 0,
							videoLengthFormat: '',
							videoUrl: '',
							passRatio: 100,
							smoothUrl: '',
							standardDefinitionUrl: '',
							hdUrl: '',
							instructor: '',
							testTime: 0,
							testTimeStr: '',
							testCount: 1,
							remarks: '',
							courseId: '',
							coverUrl: '',
							randomTopic: 1,
							sort: '',
							startLength: '',
							endLength: '',
						}
						this.videoNum = new Date(2021, 1, 1, 0, 0);
						this.testTimeNum = new Date(2021, 1, 1, 0, 0);
						this.$refs.upload.clearFiles();
						this.fileList = [];
						this.getList()
					}).finally(e => {
						loading.close()
					})
				} else {
					this.$axiosReq(video + this.form.id, null, this.form, 'put').then(res => {
						this.$message.success(res.msg)
						this.form = {
							videoTitle: '',
							videoLength: 0,
							videoLengthFormat: '',
							videoUrl: '',
							passRatio: 100,
							smoothUrl: '',
							standardDefinitionUrl: '',
							hdUrl: '',
							instructor: '',
							testTime: 0,
							testTimeStr: '',
							testCount: 1,
							remarks: '',
							courseId: '',
							coverUrl: '',
							randomTopic: 1,
							sort: '',
							startLength: '',
							endLength: '',
						}
						this.videoNum = new Date(2021, 1, 1, 0, 0);
						this.testTimeNum = new Date(2021, 1, 1, 0, 0);
						this.$refs.upload.clearFiles();
						this.fileList = [];
						this.info.addOr = 'add';
						this.getList()
					}).finally(e => {
						loading.close()
					})
				}
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.userIds = [];
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.userIds = [];
				this.getList()
			},

			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			},
			closeNow() {
				this.show = false
				this.$emit('refreshNow')
			},
			refreshDialog() {
				this.next.topicRefresh = false
				// this.close()
				// this.$emit('get-list')
			},
			refreshDialogAll() {
				this.next.topicRefresh = false
				this.show = false
			},
			videoLengthBlur(){
				var date = new Date(this.videoNum);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
				var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
				var strDate = h + m + s;
				var strNum = 0;
		　　　　var hour = strDate.split(":")[0];
		　　　　var min = strDate.split(":")[1];
		　　　　var sec = strDate.split(":")[2];
		　　　　strNum = Number(hour * 3600) + Number(min * 60) + Number(sec);
				this.form.videoLength = strNum;
				this.form.videoLengthFormat = strDate;
			},
			testTimeBlur(){
				var date = new Date(this.testTimeNum);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
				var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
				var strDate = h + m + s;
				var strNum = 0;
		　　　　var hour = strDate.split(":")[0];
		　　　　var min = strDate.split(":")[1];
		　　　　var sec = strDate.split(":")[2];
		　　　　strNum = Number(hour * 3600) + Number(min * 60) + Number(sec);
				this.form.testTime = strNum;
				this.form.testTimeStr = strDate;
			}
		}
	}
</script>

<style scoped>

</style>
