<template>
    <div class="dashboard-container">
        <Operate
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <SetUser
            v-if="dialog.refresh2"
            :dialog.sync="dialog.show2"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <Judge
            v-if="dialog.refresh3"
            :dialog.sync="dialog.show3"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <Video
            v-if="dialog.videoRefresh"
            :dialog.sync="dialog.videoShow"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <InfoView
            v-if="dialog.infoRefresh"
            :row="dialog.row"
            @refresh="refreshDialog"
        />
        <Topic
            v-if="dialog.topicRefresh"
            :dialog.sync="dialog.topicShow"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <el-card>
            <el-row type="flex" justify="space-between" :gutter="50">
                <el-col>
                    <span class="text-primary text-pad-right">发布状态</span>
                    <el-select
                        v-model="condition.releaseStatus"
                        clearable
                        value-key="region_id"
                        size="mini"
                        class="input searchInput"
                        @change="changeCondition"
                    >
                        <el-option :value="1" label="已发布" />
                        <el-option :value="0" label="未发布" />
                    </el-select>
                </el-col>
                <el-col>
                    <span class="text-primary text-pad-right">地区</span>
                    <el-cascader
                        ref="area"
                        v-model="condition.region"
                        :options="region"
                        value-key="id"
                        filterable
                        clearable
                        size="mini"
                        class="input searchInput"
                        :show-all-levels="true"
                        :props="{
                            lazy: $admin,
                            lazyLoad: lazyRegion,
                            value: 'id',
                            children: 'children',
                            label: 'areaName',
                            checkStrictly: true,
                            expandTrigger: 'hover',
                        }"
                        @change="changeArea"
                    />
                    <!--          <el-select v-model="condition.areaCode" filterable clearable size="mini" class="input searchInput" @change="changeCondition">-->
                    <!--            <el-option v-for="(v,i) in region" :key="v.region_id" :value="v.region_id" :label="v.region_name" />-->
                    <!--          </el-select>-->
                </el-col>
                <el-col>
                    <span class="text-primary text-pad-right">公司</span>
                    <SelectCompanyIds
                        v-model="condition.companyIds"
                        @change="getList"
                    />
                </el-col>
                <el-col>
                    <el-input
                        v-model="condition.courseName"
                        class="input searchInput"
                        size="mini"
                        suffix-icon="el-icon-search"
                        placeholder="搜索"
                        @input="changeCondition"
                    />
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 20px">
            <el-row>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col>
                                <el-button
                                    v-if="
                                        $hasBtn(
                                            'sys:permissions:business:course:add'
                                        )
                                    "
                                    type="primary"
                                    size="mini"
                                    @click="
                                        openDialog({
                                            title: '添加课程',
                                            addOr: 'add',
                                        })
                                    "
                                    >添加课程</el-button
                                >
                                <el-button
                                    v-if="
                                        $hasBtn(
                                            'sys:permissions:business:course:allDelete'
                                        )
                                    "
                                    type="danger"
                                    size="mini"
                                    @click="remove({ del: 'batch' })"
                                    >批量删除</el-button
                                >
                            </el-col>
                            <el-col :span="1">
                                <vxe-button @click="getList">刷新</vxe-button>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="600"
                    row-id="course_id"
                    :column-key="true"
                    :row-key="true"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :data="table.list"
                    :loading="loading"
                >
                    <vxe-table-column type="checkbox" width="60" fixed="left" />
                    <vxe-table-column type="seq" width="60" />
                    <!--          <vxe-table-column-->
                    <!--            field="course_num"-->
                    <!--            title="课程编号"-->
                    <!--          />-->
                    <vxe-table-column
                        field="courseName"
                        title="课程名称"
                        show-overflow="title"
                    />
                    <vxe-table-column field="startTime" title="生效时间" />
                    <vxe-table-column field="endTime" title="失效时间" />
                    <vxe-table-column title="课程封面">
                        <template v-slot="{ row, rowIndex }">
                            <el-popover placement="right" trigger="hover">
                                <img
                                    :src="$fileUrl + row.coursesCoverUrl"
                                    alt=""
                                    style="width: 500px; height: 500px"
                                />
                                <img
                                    slot="reference"
                                    :src="$fileUrl + row.coursesCoverUrl"
                                    alt=""
                                    style="width: 100px; height: 100px"
                                />
                            </el-popover>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column
                        field="releaseStatusName"
                        title="发布状态"
                    />
                    <vxe-table-column field="trainTypeName" title="培训类型" />
                    <vxe-table-column field="courseTypeName" title="课程类型" />
                    <vxe-table-column
                        v-if="$hasBtn('sys:permissions:business:course:status')"
                        title="上下架"
                    >
                        <template v-slot="{ row, rowIndex }">
                            <el-switch
                                ref="switch"
                                v-model="row.releaseStatus"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                :active-value="1"
                                :inactive-value="0"
                                @change="
                                    (v) => {
                                        changeStatus(v, row);
                                    }
                                "
                            />
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="课程评价" width="150">
                        <template v-slot="{ row, rowIndex }">
                            <!--              <div class="flex align-center" @click="openJudge({title:'提问回复',addOr:'judge'},row)">-->
                            <el-rate
                                v-model="row.courseGrade"
                                disabled-void-color="#fffff"
                                void-icon-class="rate-icon"
                                :colors="['#e8de05', '#e8de05', '#e8de05']"
                                disabled
                            />
                            <!--                <img src="@/assets/edit.png" alt="" style="width: 15px;height: 15px;cursor: pointer;">-->
                            <!--              </div>-->
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="" title="操作">
                        <template v-slot="{ row, rowIndex }">
                            <el-dropdown size="small" type="primary">
                                <el-button size="small" type="primary">
                                    操作<i
                                        class="el-icon-arrow-down el-icon--right"
                                    />
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        icon="el-icon-view"
                                        @click.native="lookInfo(row)"
                                        >查看</el-dropdown-item
                                    >
                                    <template>
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:business:course:edit'
                                                )
                                            "
                                            :disabled="row.isMaturity === 1"
                                            icon="el-icon-edit"
                                            @click.native="
                                                openDialog(
                                                    {
                                                        title: '修改课程',
                                                        addOr: 'upd',
                                                    },
                                                    row
                                                )
                                            "
                                            >修改</el-dropdown-item
                                        >
                                    </template>
                                    <template>
                                        <!--                                        :disabled="row.isMaturity===1"-->
                                        <el-dropdown-item
                                            icon="el-icon-edit"
                                            @click.native="multiplex(row)"
                                            >复用课程</el-dropdown-item
                                        >
                                    </template>
                                    <template v-if="row.releaseStatus != 0">
                                        <el-dropdown-item
                                            icon="el-icon-s-custom"
                                            @click.native="
                                                setUser(
                                                    {
                                                        title: '设置人员',
                                                        addOr: 'upd',
                                                    },
                                                    row
                                                )
                                            "
                                            >设置人员</el-dropdown-item
                                        >
                                    </template>
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:business:course:delete'
                                            )
                                        "
                                        icon="el-icon-delete"
                                        @click.native="
                                            remove({ del: 'single' }, row)
                                        "
                                        >删除</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        icon="el-icon-zoom-in"
                                        @click.native="
                                            openVideo(
                                                {
                                                    title: '添加视频',
                                                    addOr: 'add',
                                                },
                                                row
                                            )
                                        "
                                        >添加视频</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-row>
            <div
                v-if="multiplexShow"
                class="flex flex-justify-center flex-cont-center"
                style="
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.3);
                    position: fixed;
                    left: 0;
                    top: 0;
                    z-index: 99;
                "
            >
                <div
                    style="background-color: #ffffff; width: 30%; padding: 30px"
                >
                    <el-row style="margin-bottom: 20px">
                        <el-col :span="1"
                            ><div style="width: 1px; height: 1px"></div
                        ></el-col>
                        <el-col :span="22"
                            ><div style="text-align: center">
                                生效时间
                            </div></el-col
                        >
                        <el-col :span="1"
                            ><i
                                class="el-icon-error"
                                @click="multiplexShow = false"
                                style="font-size: 25px"
                            ></i
                        ></el-col>
                    </el-row>
                    <el-form ref="ruleForm" label-width="110px" class="allForm">
                        <el-form-item
                            label="生效时间"
                            :required="true"
                            class="formInput"
                        >
                            <el-date-picker
                                v-model="time"
                                type="daterange"
                                unlink-panels
                                :clearable="false"
                                class="formInput w-100"
                                value-format="yyyy-MM-dd"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                @change="changeDate"
                            />
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import SelectCompanyIds from "./components/SelectCompanyIds.vue";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { course, getAreaChildren, getAreaTreeByCode, video } from "@/api";
import Operate from "./Operate";
import Video from "./video";
import InfoView from "./info";
import Topic from "./topic";
import SetUser from "./SetUser";
import Judge from "./JudgeDetail/Judge";
export default {
    name: "Index",
    components: {
        Operate,
        SetUser,
        Judge,
        Topic,
        Video,
        InfoView,
        SelectCompanyIds,
    },
    data() {
        return {
            loading: false,
            condition: {
                areaCode: null,
                courseName: "",
                releaseStatus: "",
                companyIds: [],
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                refresh2: false, // 每次刷新DOM
                refresh3: false, // 每次刷新DOM
                videoRefresh: false, // 每次刷新DOM
                topicRefresh: false, // 每次刷新DOM
                show: false,
                show2: false,
                show3: false,
                videoShow: false,
                topicShow: false,
                infoRefresh: false,
            },
            region: [],
            multiplexShow: false,
            multiplexId: "",
            time: "",
            startTime: "",
            endTime: "",
        };
    },
    created() {
        let pageSizeS = localStorage.getItem("pageSize");
        if (pageSizeS) {
            this.table.pageSize = Number(pageSizeS);
        }
        if (!this.$admin) this.getAreaChildren();
        this.getList();
    },
    mounted() {},
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        openVideo(info, row) {
            this.dialog.videoRefresh = true;
            this.dialog.videoShow = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        openTopic(info, row) {
            this.dialog.topicRefresh = true;
            this.dialog.topicShow = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        // 打开提问
        openJudge(info, row) {
            this.dialog.refresh3 = true;
            this.dialog.show3 = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        // 打开dialog
        setUser(info, row) {
            this.dialog.refresh2 = true;
            this.dialog.show2 = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        getAreaChildren() {
            this.loading = true;
            this.$axiosReq(
                getAreaTreeByCode + this.$regionCode,
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.region = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        lazyRegion(v, resolve) {
            // 异步加载子节点
            this.$axiosReq(
                getAreaChildren,
                null,
                {
                    parentId: v.value,
                },
                "get"
            ).then((res) => {
                const arr = [];
                for (const i of res.data) {
                    arr.push({
                        ...i,
                        hasChild: i.level < 3,
                    });
                }
                resolve(arr);
            });
        },
        changeArea(v) {
            const node = this.$refs.area.getCheckedNodes();
            if (node.length > 0) {
                this.condition.areaCode = node[0].data.areaCode;
            } else {
                this.condition.areaCode = "";
            }
            this.getList();
        },
        changeCondition() {
            this.table.currentPage = 1;
            this.getList();
        },
        changeStatus(v, row) {
            this.$axiosReq(course + row.id, null, null, "patch")
                .then((res) => {
                    this.$message.success(res.msg);
                })
                .finally(() => {
                    this.getList();
                });
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                "/course/server/escort/web/course/list",
                {
                    areaCode: this.condition.areaCode,
                    companyIds: this.condition.companyIds,
                    courseName: this.condition.courseName,
                    releaseStatus: this.condition.releaseStatus,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                null,
                "post"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        remove(info, row) {
            const id = [];
            if (info.del === "batch") {
                const arr = this.$refs.table.getCheckboxRecords();
                const arr1 = this.$refs.table.getCheckboxReserveRecords();
                const batchDel = arr.concat(arr1);
                for (const i of batchDel) {
                    id.push(i.id);
                }
                if (id.length === 0) {
                    this.$message.error("请勾选！！！");
                    return;
                }
                this.$confirm("确认删除吗！！！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(
                            course,
                            null,
                            {
                                ids: id.join(),
                            },
                            "delete"
                        ).then((res) => {
                            this.$message.success(res.msg);
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message.info("取消了删除！！！");
                    });
            } else {
                this.$confirm("确认删除吗！！！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(
                            course + row.id,
                            null,
                            null,
                            "delete"
                        ).then((res) => {
                            this.$message.success(res.msg);
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message.info("取消了删除！！！");
                    });
            }
        },
        handleSizeChange(pageSize) {
            localStorage.setItem("pageSize", JSON.stringify(pageSize));
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        refreshDialog() {
            this.dialog.refresh = false;
            this.dialog.refresh2 = false;
            this.dialog.refresh3 = false;
            this.dialog.videoRefresh = false;
            this.dialog.topicRefresh = false;
            this.dialog.infoRefresh = false;
        },
        lookInfo(val) {
            this.dialog.infoRefresh = true;
            this.dialog.row = val;
        },
        changeDate(v) {
            if (v) {
                this.startTime = v[0];
                this.endTime = v[1];
            } else {
                this.startTime = "";
                this.endTime = "";
            }
            this.$axiosReq(
                "/course/server/escort/web/course/pasteCourse/",
                null,
                {
                    id: this.multiplexId,
                    startTime: this.startTime,
                    endTime: this.endTime,
                },
                "put"
            ).then((res) => {
                this.$message.success(res.msg);
                this.multiplexShow = false;
                this.getList();
            });
        },
        multiplex(obj) {
            this.startTime = "";
            this.endTime = "";
            this.time = "";
            this.multiplexShow = true;
            this.multiplexId = obj.id;
        },
    },
};
</script>

<style scoped>
::v-deep .el-rate i {
    font-size: 22px !important;
    margin: 0;
}

::v-deep .el-rate span {
    cursor: pointer !important;
}
</style>
