<template>
    <el-dialog
        :visible.sync="show"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <NextCourse
            v-if="next.refresh"
            :dialog.sync="next.show"
            :info="next.info"
            @refresh="refreshDialog"
        />
        <Video
            v-if="next.videoRefresh"
            :dialog.sync="next.videoShow"
            :row="next.row"
            :info="next.info"
            @refresh="refreshDialog"
            @refreshNow="refreshDialogNow"
        />

        <div slot="title" class="header-title">
            <el-row :gutter="5">
                <el-col :span="24">
                    <span class="title-name">{{ info.title }}</span>
                </el-col>
            </el-row>
        </div>
        <el-row :gutter="0">
            <el-col :span="24">
                <el-form
                    ref="ruleForm"
                    label-width="110px"
                    class="allForm"
                    :disabled="info.addOr === 'view'"
                >
                    <el-form-item label="课程名称" :required="true">
                        <el-input v-model="form.courseName" class="formInput" />
                    </el-form-item>
                    <el-form-item
                        label="生效时间"
                        :required="true"
                        class="formInput"
                    >
                        <el-date-picker
                            v-model="time"
                            type="daterange"
                            unlink-panels
                            class="formInput w-100"
                            value-format="yyyy-MM-dd"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            @change="changeDate"
                        />
                    </el-form-item>
                    <!-- <el-form-item
                        label="分配企业"
                        :required="true"
                        class="formInput companyNameAll"
                    >
                        <div class="companyNameList">
                            <div>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="filterText"
                                    @input="citySearch"
                                    style="width: 260px; height: 40px"
                                >
                                </el-input>
                                <div class="companyNameAllListNow">
                                    <el-tree
                                        :data="allCity"
                                        show-checkbox
                                        node-key="areaCode"
                                        :props="defaultProps"
                                        class="filter-tree"
                                        ref="tree"
                                        @check="treeChange"
                                        @check-change="treeChangeClick"
                                    />
                                </div>
                                <div
                                    class="flex-row-box flex-wrap"
                                    style="width: 260px"
                                >
                                    <div
                                        v-for="(item, index) in cityName"
                                        :key="index"
                                    >
                                        <div
                                            v-if="cityName.length > 0"
                                            class="pl-1"
                                        >
                                            {{ item }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-input
                                    @change="getCompanyListAll"
                                    placeholder="请输入内容"
                                    v-model="companyNameNow"
                                    style="width: 260px; height: 40px"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-search"
                                    ></i>
                                </el-input>
                                <div class="companyNameAllListNow">
                                    <el-checkbox
                                        :indeterminate="isIndeterminate"
                                        v-model="sonIdsAll"
                                        @change="handleCheckAllChange"
                                        >全选</el-checkbox
                                    >
                                    <el-checkbox-group
                                        v-model="form.companyIds"
                                        size="medium"
                                        @change="changeCascaderAll"
                                    >
                                        <el-checkbox
                                            v-for="v in company"
                                            :key="v.companyId"
                                            :label="v.companyId"
                                            style="width: 260px"
                                            >{{ v.companyName }}</el-checkbox
                                        >
                                    </el-checkbox-group>
                                </div>
                                <div v-if="form.companyIds.length > 0">
                                    已选择{{ form.companyIds.length }}家企业
                                </div>
                            </div>
                        </div>
                    </el-form-item> -->
                    <!-- <el-form-item
                        label="部门"
                        class="formInput"
                        v-if="form.companyIds.length == 1"
                    >
                        <el-select
                            v-model="form.departIds"
                            multiple
                            class="w-100"
                        >
                            <el-option
                                v-for="(v, i) in depart"
                                :key="i"
                                :value="v.id"
                                :label="v.departName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="岗位" class="formInput">
                        <el-select
                            v-model="form.positionIds"
                            value-key="id"
                            class="w-100"
                            multiple
                            filterable
                        >
                            <el-option
                                v-for="v in position"
                                :key="v.id"
                                :value="v.id"
                                :label="v.positionName"
                            />
                        </el-select>
                    </el-form-item> -->
                    <el-form-item
                        label="课程分类"
                        :required="true"
                        class="formInput"
                    >
                        <el-cascader
                            ref="area"
                            v-model="form.classifyId"
                            :options="classify"
                            value-key="id"
                            filterable
                            clearable
                            class="formInput w-100"
                            :show-all-levels="true"
                            :props="{
                                value: 'id',
                                label: 'typeName',
                                emitPath: false,
                                checkStrictly: true,
                            }"
                        />
                    </el-form-item>
                    <el-form-item
                        label="培训类型"
                        :required="true"
                        class="formInput"
                    >
                        <el-select v-model="form.trainType" class="w-100">
                            <el-option
                                v-for="(v, i) in trainType"
                                :key="i"
                                :value="v.dictCode"
                                :label="v.dictName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="课程类型"
                        :required="true"
                        class="formInput"
                    >
                        <el-select v-model="form.courseType" class="w-100">
                            <el-option
                                v-for="(v, i) in courseType"
                                :key="i"
                                :value="v.dictCode"
                                :label="v.dictName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="课程封面" :required="true">
                        <el-upload
                            ref="upload"
                            class="upload-demo"
                            drag
                            :action="$srcUrl + upFile"
                            :headers="{ Authorization: $token }"
                            list-type="picture"
                            :file-list="fileList"
                            :auto-upload="true"
                            :limit="1"
                            :on-change="change"
                            :on-success="uploadFile"
                            :on-exceed="overFile"
                        >
                            <i
                                class="el-icon-receiving"
                                style="font-size: 80px"
                            />
                            <div class="el-upload__text">
                                将文件拖到此处，或<em>点击上传</em>，只支持传一张图片
                            </div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="课程介绍" :required="true">
                        <el-input
                            v-model="form.courseIntroduce"
                            class="formInput"
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                        />
                    </el-form-item>
                    <el-form-item label="课程备注">
                        <el-input
                            v-model="form.remarks"
                            class="formInput"
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                        />
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="commit">下一步</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {
    course,
    company,
    depart,
    position,
    getDictParentCode,
    upFile,
    getCourseType,
    positionCompanyIds,
    areaTreeAll,
    companyAll,
} from "@/api";
import { uploadAccept } from "@/utils/uploadAccept/index";
import NextCourse from "./NextCourse";
import Video from "./video";
export default {
    name: "Operate",
    components: {
        NextCourse,
        Video,
    },
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            filterText: "",
            show: this.dialog,
            next: {
                show: false,
                refresh: false,
                videoRefresh: false, // 每次刷新DOM
                videoShow: false,
                info: {},
            },
            time: "",
            form: {
                courseName: "",
                endTime: "",
                startTime: "",
                trainType: "",
                courseType: "",
                courseIntroduce: "",
                remarks: "",
                coursesCoverUrl: "",
                classifyId: "",
                // companyIds: [],
                // positionIds: [],
                // cityCode: [],
                // departIds: [],
            },
            cityCode: [],
            fileList: [],
            courseType: [],
            trainType: [],
            company: [],
            depart: [],
            position: [],
            classify: "",
            upFile,
            changeCompanyI: 0,
            defaultProps: {
                children: "children",
                label: "areaName",
            },
            allCity: [],
            checkboxGroup: [],
            companyNameNow: "",
            sonIdsAll: [],
            isIndeterminate: true,
            check: false,
            cityName: [],
        };
    },
    created() {
        this.getCompanyListAll();
        // this.getPositionList()
        this.getCourseType();
        this.getTrainType();
        this.getClassify();
        this.upd();
        this.getAreaTreeAll();
    },
    methods: {
        citySearch() {
            var value = this.filterText;
            if (value) {
                var list = this.allCity;
                this.allCity = [];
                var isVal = null;
                var isVal1 = null;
                var isVal2 = null;
                for (let i = 0; i < list.length; i++) {
                    isVal = list[i].areaName.indexOf(value);
                    if (isVal == -1) {
                        var vv = list[i];
                        if (vv.children) {
                            for (let f = 0; f < vv.children.length; f++) {
                                isVal1 = vv.children[f].areaName.indexOf(value);
                                if (isVal1 == -1) {
                                    var cc = vv.children[f];
                                    if (cc.children) {
                                        for (
                                            let e = 0;
                                            e < cc.children.length;
                                            e++
                                        ) {
                                            isVal2 = cc.children[
                                                e
                                            ].areaName.indexOf(value);
                                            if (isVal2 != -1) {
                                                // //console.log(cc.children[e])
                                                this.allCity.push(
                                                    cc.children[e]
                                                );
                                            }
                                        }
                                    }
                                } else {
                                    // //console.log(list[i].children[f].children)
                                    this.allCity.push(vv.children[f]);
                                }
                            }
                        }
                    } else {
                        this.allCity.push(list[i]);
                    }
                }
            } else {
                this.getAreaTreeAll();
            }
        },
        getAreaTreeAll() {
            this.loading = true;
            this.$axiosReq(areaTreeAll, null, null, "get")
                .then((res) => {
                    this.allCity = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        upd() {
            if (this.info.addOr !== "add") {
                this.fileList = [];
                this.$axiosReq(course + this.row.id, null, null, "get").then(
                    (res) => {
                        this.form = res.data;
                        this.form.trainType = this.form.trainType + "";
                        this.form.courseType = this.form.courseType + "";

                        this.time = [this.form.startTime, this.form.endTime];
                        this.fileList.push({
                            url: this.$fileUrl + this.form.coursesCoverUrl,
                        });
                        // if (res.data.positionIds) {
                        //     this.form.positionIds = JSON.parse(
                        //         JSON.stringify(res.data.positionIds)
                        //     );
                        // } else {
                        //     this.form.positionIds = [];
                        // }
                        // if (!res.data.companyIds) {
                        //     this.form.companyIds = [];
                        // }
      //                   this.changeCompany();
						// if (this.form.companyIds.length == 1) {
						//     this.getDepart();
						// }
                    }
                );
            }
        },
        change(file, fileList) {
            const fileSize = file.size / 1024 / 1024 < 500;
            if (!fileSize) {
                this.$message.error("文件大小不能超过500MB！！！");
                return false;
            }
            let flag = false;
            flag = uploadAccept(file, "图片");
            if (flag) {
                // this.form.file = file
            } else {
                fileList.splice(-1, 1);
            }
        },
        changeDate(v) {
            if (v) {
                this.form.startTime = v[0];
                this.form.endTime = v[1];
            } else {
                this.form.startTime = "";
                this.form.endTime = "";
            }
        },
        getDepart() {
            this.$axiosReq(
                depart,
                null,
                {
                    companyId: this.form.companyIds[0],
                    type: 0,
                },
                "get"
            ).then((res) => {
                this.depart = res.data;
            });
        },
        uploadFile(res) {
            if (res.code !== 200) {
                this.$message.error(res.msg);
                this.$refs.upload.clearFiles();
            }
            this.form.coursesCoverUrl = res.data.filePath;
        },
        overFile() {
            this.$message.error("只能传一个！");
        },
        getCompanyList() {
            var that_ = this;
            this.$axiosReq(
                company,
                null,
                {
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                that_.company = res.data.content;
            });
        },
        getCompanyListAll() {
            var that_ = this;
            this.$axiosReq(
				companyAll + "/" + that_.companyNameNow,
				{
					areaCodes: that_.cityCode,
				},
				null,
				"post"
            ).then((res) => {
                // //console.log(JSON.stringify(res.data))
                that_.company = res.data;
            });
        },
        unique(arr) {
            const res = new Map();
            return arr.filter(
                (arr) => !res.has(arr.companyId) && res.set(arr.companyId, 1)
            );
        },
        changeCascaderAll() {
            var that_ = this;
            that_.changeCompany();
            if (this.form.companyIds.length == 1) {
                this.getDepart();
            } else {
                this.form.departIds = [];
            }
        },
        handleCheckAllChange(v) {
            var data = this.company;
            if (v) {
                var index = null;
                for (let i = 0; i < data.length; i++) {
                    index = this.form.companyIds.indexOf(data[i].companyId);
                    if (index == "-1") {
                        this.form.companyIds.push(data[i].companyId);
                    }
                }
            } else {
                for (let i = 0; i < data.length; i++) {
                    this.form.companyIds = this.form.companyIds.filter(
                        (item) => item != data[i].companyId
                    );
                }
            }

            this.isIndeterminate = false;
            this.changeCompany();
        },
        treeChangeClick(data, check) {
            this.check = check;
        },
        treeChange(data) {
            if (this.check) {
                this.$refs.tree.setCheckedKeys([data.areaCode]);
                if (data.level == 3) {
                    this.cityCode = [];
                    this.cityCode.push(data.areaCode);
                    var isActive = this.cityName.indexOf(data.areaName);
                    if (isActive == "-1") {
                        this.cityName.push(data.areaName);
                    }
                } else if (data.level == 2) {
                    var dataAll = data.children;
                    this.cityCode = [];
                    for (let i = 0; i < dataAll.length; i++) {
                        this.cityCode.push(dataAll[i].areaCode);
                        var isActive = this.cityName.indexOf(
                            dataAll[i].areaName
                        );
                        if (isActive == "-1") {
                            this.cityName.push(dataAll[i].areaName);
                        }
                    }
                } else if (data.level == 1) {
                    var dataAll = data.children;
                    this.cityCode = [];
                    for (let i = 0; i < dataAll.length; i++) {
                        for (let f = 0; f < dataAll[i].children.length; f++) {
                            this.cityCode.push(dataAll[i].children[f].areaCode);
                            var isActive = this.cityName.indexOf(
                                dataAll[i].children[f].areaName
                            );
                            if (isActive == "-1") {
                                this.cityName.push(
                                    dataAll[i].children[f].areaName
                                );
                            }
                        }
                    }
                }
                this.getCompanyListAll();
            } else {
                if (data.level == 3) {
                    this.cityCode = this.cityCode.filter(
                        (item) => item != data.areaCode
                    );
                    this.cityName = this.cityName.filter(
                        (item) => item != data.areaName
                    );
                } else if (data.level == 2) {
                    var dataAll = data.children;
                    for (let i = 0; i < dataAll.length; i++) {
                        this.cityCode = this.cityCode.filter(
                            (item) => item != dataAll[i].areaCode
                        );
                        this.cityName = this.cityName.filter(
                            (item) => item != dataAll[i].areaName
                        );
                    }
                } else if (data.level == 1) {
                    var dataAll = data.children;
                    for (let i = 0; i < dataAll.length; i++) {
                        for (let f = 0; f < dataAll[i].children.length; f++) {
                            this.cityCode = this.cityCode.filter(
                                (item) =>
                                    item != dataAll[i].children[f].areaCode
                            );
                            this.cityName = this.cityName.filter(
                                (item) =>
                                    item != dataAll[i].children[f].areaName
                            );
                        }
                    }
                }

                this.getCompanyListAll();
            }
        },
        changeCompany() {
            this.$axiosReq(
                positionCompanyIds,
                this.form.companyIds,
                null,
                "post"
            ).then((res) => {
                if (this.info.addOr === "add") {
                    this.form.positionIds = [];
                } else {
                    this.changeCompanyI++;
                }
                if (this.changeCompanyI >= 2) {
                    this.form.positionIds = [];
                }
                this.position = res.data;
            });
        },
        getPositionList() {
            this.$axiosReq(
                position,
                null,
                {
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                this.position = res.data.content;
            });
        },
        getCourseType() {
            this.$axiosReq(
                getDictParentCode + "COURSE_TYPE",
                null,
                null,
                "get"
            ).then((res) => {
                this.courseType = res.data;
            });
        },
        getTrainType() {
            this.$axiosReq(
                getDictParentCode + "TRAINING_TYPE",
                null,
                null,
                "get"
            ).then((res) => {
                this.trainType = res.data;
            });
        },
        getClassify() {
            this.$axiosReq(getCourseType, null, null, "get").then((res) => {
                this.classify = res.data;
            });
        },
        // 提交添加
        commit() {
            if (this.info.addOr !== "view") {
                if (this.$textNull(this.form.courseName)) {
                    this.$message.error("请填写课程名称！");
                    return;
                } else if (this.$textNull(this.time)) {
                    this.$message.error("请选择生效时间！");
                    return;
                } else if (this.$textNull(this.form.classifyId)) {
                    this.$message.error("请选择课程分类！");
                    return;
                } else if (this.$textNull(this.form.trainType)) {
                    this.$message.error("请选择培训类型！");
                    return;
                } else if (this.$textNull(this.form.courseType)) {
                    this.$message.error("请选择课程类型！");
                    return;
                } else if (this.$textNull(this.form.coursesCoverUrl)) {
                    this.$message.error("请选择课程封面！");
                    return;
                } else if (this.$textNull(this.form.courseIntroduce)) {
                    this.$message.error("请填写课程介绍！");
                    return;
                }
            }
            if (this.info.addOr === "add") {
                this.$axiosReq(course, this.form, null, "post").then((res) => {
                    this.$message.success(res.msg);
                    this.next.row = {
                        id: res.data.id,
                    };
                    this.next.info = {
                        addOr: "add",
                    };
					this.row = {
						id: res.data.id
					};
                    this.next.videoShow = true;
                    this.next.videoRefresh = true;
                });
            } else if (this.info.addOr === "upd") {
                this.$axiosReq(
                    course + this.row.id,
                    this.form,
                    null,
                    "put"
                ).then((res) => {
                    this.$message.success(res.msg);
                    this.next.row = {
                        id: this.row.id,
                    };
                    this.next.info = {
                        addOr: "add",
                    };
                    this.next.videoShow = true;
                    this.next.videoRefresh = true;
                });
            } else {
                this.next.row = {
                    id: this.row.id,
                };
                this.next.info = {
                    addOr: "add",
                };
                this.next.videoShow = true;
                this.next.videoRefresh = true;
            }
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
        refreshDialog() {
            this.next.refresh = false;
            this.next.videoRefresh = false;
            this.close();
            this.$emit("get-list");
        },
		refreshDialogNow(){
			this.info.addOr = "upd";
			this.upd();
			this.next.videoRefresh = false;
		}
    },
};
</script>

<style scoped>
.companyNameList {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.companyNameAllList {
    width: 260px;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    border: 1px solid #d9d8d3;
    padding: 5px;
    margin-right: 20px;
}

.companyNameAllList::-webkit-scrollbar {
    display: none;
}

.companyNameAllListNow {
    width: 260px;
    height: 160px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    border: 1px solid #d9d8d3;
    padding: 5px;
    margin-right: 20px;
}

.companyNameAllListNow::-webkit-scrollbar {
    display: none;
}
.el-form{
    padding-right: 25px;
}
</style>
